<template>
  <div>
    <v-dialog content-class="create-general-memo-modal" v-model="editPermitMemo" persistent scrollable max-width="960"
      origin="center center" overlay-opacity=".3">
      <v-card id="editPermitMemo" class="height-95vh" v-if="
        recipientList &&
        recipientList.length > 0 &&
        form.izin_prinsip_recipients
      ">
        <v-card-title class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("card.create-permit-memo") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown && userIsKrani">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind:data-hint="$t('introjs.this-button-for-submit-action')" data-hintposition="middle-middle"
                  data-position="bottom-right-aligned" v-bind:loading="axiosProcessing"
                  v-bind:disabled="axiosProcessing" color="blue-grey" class="ma-2 white--text"
                  v-on:click="menuAction('submit')" v-on="on" v-bind="attrs">
                  <v-icon dark class="mr-2">
                    {{ iconSubmit }}
                  </v-icon>
                  {{ $t("button.submit") }}
                </v-btn>
              </template>
              <span>{{ $t("tooltip.submit-to-upline") }}</span>
            </v-tooltip>

            <v-menu v-if="!$vuetify.breakpoint.smAndDown && !userIsKrani" bottom left>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-bind:data-hint="$t('introjs.this-button-for-submit-action')
                      " v-bind:disabled="axiosProcessing" v-bind:loading="axiosProcessing"
                      data-hintposition="middle-middle" data-position="bottom-right-aligned" color="blue-grey" dark
                      v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon class="mr-2">{{ iconSubmit }}</v-icon>
                      {{ $t("button.submit") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.select-submit-to") }}</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item v-for="(item, i) in menus" v-bind:key="i" v-on:click="menuAction(item.command)">
                  <v-icon class="mr-3">{{ item.icon }}</v-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind:data-hint="$t('introjs.this-button-for-save-as-draft')" data-hintposition="middle-middle"
                  data-position="bottom-right-aligned" v-bind:loading="axiosProcessing"
                  v-bind:disabled="axiosProcessing" color="blue-grey" class="ma-2 white--text"
                  v-on:click="menuAction('save')" v-on="on" v-bind="attrs">
                  <v-icon dark class="mr-2">
                    {{ iconSave }}
                  </v-icon>
                  {{ $t("button.save") }}
                </v-btn>
              </template>
              <span>{{ $t("tooltip.save-as-draft") }}</span>
            </v-tooltip>

            <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ iconMenu }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, i) in mobileMenus" v-bind:key="i" v-on:click="menuAction(item.command)">
                  <v-icon class="mr-3">{{ item.icon }}</v-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on:click="showHelper" v-on="on" v-bind="attrs">
                  <v-icon>{{ iconHelp }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.help-me") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on:click="editPermitMemo = false" v-on="on" v-bind="attrs">
                  <v-icon>{{ iconClose }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.close-not-save") }}</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text height="100%">
          <v-row class="mt-3">
            <v-col cols="12" md="10" sm="12" class="pl-0 pr-0">
              <v-alert v-if="
                $v.form.izin_prinsip_recipients.$error &&
                !$v.form.izin_prinsip_recipients.required
              " dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0">
                {{ $t("error.select-at-least-one-memo-recipient") }}
              </v-alert>

              <v-alert v-if="recipientSenderSimilar" dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0">
                {{
                  $t("error.recipient-and-direksi-must-not-have-the-same-items")
                }}
              </v-alert>

              <v-autocomplete v-bind:data-hint="$t('introjs.this-is-permit-memo-recipient-selector')
                " data-hintposition="middle-middle" data-position="bottom-left-aligned" hide-details="true"
                v-model="form.izin_prinsip_recipients" v-bind:disabled="isUpdating" v-bind:items="recipientList"
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }" hide-selected deletable-chips small-chips v-bind:search-input.sync="searchInput"
                v-on:change="searchInput = ''" chips color="blue-grey lighten-2"
                v-bind:label="$t('label.select-memo-recipients')" item-text="nama" item-value="id" multiple clearable>
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" v-bind:input-value="data.selected" close v-on:click="data.select"
                    v-on:click:close="removePenerima(data.item)">
                    <v-avatar left>
                      <v-img v-if="
                        data.item.kelompok != 'GROUP' && data.item.avatar_path
                      " v-bind:src="data.item.avatar_path"></v-img>
                      <v-icon v-else-if="
                        data.item.kelompok != 'GROUP' &&
                        !data.item.avatar_path
                      " class="mr-2">
                        {{ iconUser }}
                      </v-icon>
                      <v-icon v-else class="mr-2">
                        {{ iconGroup }}
                      </v-icon>
                    </v-avatar>
                    {{ data.item.nama }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== `object`">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img v-if="
                        data.item.kelompok != 'GROUP' && data.item.avatar_path
                      " v-bind:src="data.item.avatar_path" />
                      <v-icon v-else-if="
                        data.item.kelompok != 'GROUP' &&
                        !data.item.avatar_path
                      ">
                        {{ iconUser }}
                      </v-icon>
                      <v-icon v-else>
                        {{ iconGroup }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.nama"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.kelompok"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="pa-0 vertical-all-child">
              <v-tooltip bottom v-if="userHQ">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" class="avoore-icon-checkbox checkbox--primary checkbox--user-tie ml-2">
                    <input id="chk-ep-bom" type="checkbox" v-model="form.is_bom" />
                    <label for="chk-ep-bom"></label>
                  </div>
                </template>
                <span>{{ $t("tooltip.directors-memo") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" class="avoore-icon-checkbox checkbox--primary checkbox--eye ml-4">
                    <input id="chk-ep-secret" type="checkbox" v-model="form.is_rahasia" />
                    <label for="chk-ep-secret"></label>
                  </div>
                </template>
                <span>{{ $t("tooltip.this-memo-is-confidential") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
            <v-row v-if="form.is_bom">
            <v-col cols="12" class="pl-0 pr-0">
              <v-alert v-if="$v.form.direksi.$error && !$v.form.direksi.required" dense type="error" :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-0 mt-4">
                {{ $t("error.select-exactly-one-director-sevp") }}
              </v-alert>

              <v-autocomplete :data-hint="$t('introjs.this-is-director-selector')" data-hintposition="middle-middle"
                data-position="bottom-right-aligned" v-model="form.direksi" v-bind:items="dirSevps"
                v-bind:filter="dirSevpsFilter" v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }" auto-select-first hide-details="true" item-text="nama" item-value="id" clearable
                label="Direktur/SEVP/Komisaris" placeholder="Direktur/SEVP/Komisaris">
                <template slot="selection" slot-scope="data">
                  {{ data.item.nama }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-0 pr-0">
              <v-alert v-if="$v.form.hal.$error && !$v.form.hal.required" dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4">
                {{ $t("error.memo-subject-must-be-filled") }}
              </v-alert>
              <v-alert v-if="$v.form.hal.$error && !$v.form.hal.minLength" dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4">
                {{
                  $t("error.memo-subject-min-length") +
                  " 10 " +
                  $t("label.characters")
                }}
              </v-alert>
              <v-alert v-if="$v.form.hal.$error && !$v.form.hal.maxLength" dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4">
                {{
                  $t("error.memo-subject-max-length") +
                  " 250 " +
                  $t("label.characters")
                }}
              </v-alert>
              <v-text-field v-bind:data-hint="$t('introjs.this-is-memo-subject')" data-hintposition="middle-middle"
                data-position="bottom-right-aligned" v-model="form.hal" hide-details="true" maxlength="250"
                v-bind:hint="$t('tooltip.min-max-char-for-memo-subject')"
                v-bind:label="$t('label.memo-subject')"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-0 pr-0">
              <v-alert v-if="$v.form.isi.$error && !$v.form.isi.required" dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-4">
                {{ $t("error.memo-message-must-be-filled") }}
              </v-alert>
              <v-alert v-if="$v.form.isi.$error && !$v.form.isi.minLength" dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4">
                {{
                  $t("error.memo-message-min-length") +
                  " 27 " +
                  $t("label.characters")
                }}
              </v-alert>
              <v-alert v-if="$v.form.isi.$error && !$v.form.isi.maxLength" dense type="error" v-bind:icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4">
                {{
                  $t("error.memo-message-max-length") +
                  " 100000 " +
                  $t("label.characters")
                }}
              </v-alert>
            </v-col>
            <froala-editor v-bind:data-hint="$t('introjs.this-is-memo-message')" data-hintposition="middle-middle"
              data-position="bottom-right-aligned" v-bind:oldEditorData="form.isi" v-on:editorData="changeEditorData"
              v-on:froalaIsWorking="froalaIsWorking" v-bind:editorDisabled="editorDisabled"></froala-editor>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <div class="attachment-list mt-4" v-if="
                (form.attachments && form.attachments.length > 0) ||
                (currPAttachments && currPAttachments.length > 0)
              ">
                <p class="mb-1 text--avore-grey" v-html="$t('text.new-attachment-list')"></p>
                <ul>
                  <li v-for="(item, index) in currPAttachments" v-bind:key="index" v-bind:value="item.id"
                    class="file--list">
                    <a href="javascript:;" class="server--file">
                      <span>{{ index + 1 }}.</span>
                      {{ item.file_name }} - {{ item.file_size | formatSize }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon class="ml-2" color="red" v-on:click="confirmDeleteAttachment(item, index)"
                            v-on="on" v-bind="attrs">
                            <v-icon>{{ iconDelete }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("tooltip.delete-this-file") }}</span>
                      </v-tooltip>
                    </a>
                  </li>
                  <li v-for="(item, idx) in form.attachments" v-bind:key="item.id" v-bind:value="item.id"
                    class="file--list">
                    <a href="javascript:;">
                      <span>{{ idx + 1 + currPAttachments.length }}.</span>
                      {{ item.name }} - {{ item.size | formatSize }}
                      <span v-if="item.error">{{ item.error }}</span>
                      <span v-else-if="item.success">success</span>
                      <span v-else-if="item.active">active</span>
                      <span v-else></span>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon class="ml-2" color="red" v-on:click="deleteAttachment(item)" v-on="on"
                            v-bind="attrs">
                            <v-icon>{{ iconDelete }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("tooltip.delete-this-file") }}</span>
                      </v-tooltip>
                    </a>
                  </li>
                </ul>
              </div>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <file-upload v-on="on" input-id="edit-permit" v-bind="attrs"
                    class="mt-4 att-button v-btn v-btn--is-elevated v-btn--has-bg v-size--default blue-grey"
                    post-action="/upload/post" extensions="jpg,jpeg,png,pdf,doc,docx,xls,xlsx,zip,rar,ppt,pptx"
                    accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation
                    , application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword,
                    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                     v-bind:size="1024 * 1024 * 35"
                    v-bind:active="true" v-bind:maximum="3" v-model="form.attachments" v-on:input-filter="inputFilter"
                    v-on:input-file="inputFile" ref="permitUpload" v-bind:title="$t('tooltip.add-attachments')">
                    <v-icon class="text--white">{{ iconAttachment }}</v-icon>
                  </file-upload>
                </template>
                <span>{{ $t("tooltip.add-attachments") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-overlay v-bind:absolute="true" v-bind:value="editorIsWorking">
        </v-overlay>
      </v-card>

      <v-card v-if="
        (!recipientList && recipientList.length < 1) ||
        !form.izin_prinsip_recipients
      " style="height: 100% !important">
        <v-skeleton-loader class="mx-auto" max-width="100%" width="100%"
          type="actions, article, list-item-three-line"></v-skeleton-loader>
      </v-card>
    </v-dialog>
    <upline-selector v-bind:showUplineDialog="showUplineDialog" v-on:showHideUplineDialog="showHideUplineDialog"
      v-on:uplineSelected="uplineSelected"></upline-selector>

    <peer-selector v-bind:showPeerDialog="showPeerDialog" v-on:showHidePeerDialog="showHidePeerDialog"
      v-on:peerSelected="peerSelected"></peer-selector>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import froalaEditor from "./FroalaEditor";
import VueUploadComponent from "vue-upload-component";
import uplineSelector from "./UplineSelector.vue";
import peerSelector from "./PeerSelector.vue";
import {
  mdiContentSave,
  mdiMenu,
  mdiSend,
  mdiClose,
  mdiBookArrowUp,
  mdiAccountGroup,
  mdiAccount,
  mdiAlertCircleOutline,
  mdiHelpCircleOutline,
  mdiAttachment,
  mdiDeleteForever,
} from "@mdi/js";

import toolbarMenu from "@/constants/toolbarmenu";
import { validationMixin } from "vuelidate";
import introJS from "intro.js/intro.js";
import "intro.js/minified/introjs.min.css";

const createHelper = introJS();

const { required, minLength, maxLength, requiredIf } = require("vuelidate/lib/validators");

function dirAndRecipientMustNotSame() {
  let arr1 = this.form.izin_prinsip_recipients
  let arr2 = this.form.direksi
  if (!arr1 || !arr2) return true;
  if (arr1.length < 1 || arr2.length < 1) return true;
  let result = arr1.filter(e => arr2.includes(e));
  return result.length < 1;
}

export default {
  name: "create-permit-memo-modal",
  components: {
    froalaEditor,
    "file-upload": VueUploadComponent,
    uplineSelector,
    peerSelector,
  },
  data() {
    // const srcs = {
    //   1: "https://image.flaticon.com/icons/png/128/709/709722.png"
    // };
    return {
      iconSave: mdiContentSave,
      iconSubmit: mdiSend,
      iconSubmitUpline: mdiBookArrowUp,
      iconMenu: mdiMenu,
      iconClose: mdiClose,
      iconGroup: mdiAccountGroup,
      iconUser: mdiAccount,
      iconAlert: mdiAlertCircleOutline,
      iconHelp: mdiHelpCircleOutline,
      iconAttachment: mdiAttachment,
      iconDelete: mdiDeleteForever,
      menus: toolbarMenu.createDraftToolbarMenu,
      editorIsWorking: false,
      timeout: null,
      loader: null,
      axiosProcessing: false,
      autoUpdate: true,
      isUpdating: false,
      searchInput: "",
      hasCC: false,
      tipeKrani: ["KRANI", "SEKRETARIS_KRANI", "SEKRETARIS_DIRSEVP"],
      rules: [(v) => v.length <= 500 || this.$t("tooltip.max-500-char")],
      subjectTextRules: [
        (v) => !!v || this.$t("tooltip.this-field-is-required"),
        (v) =>
          (v && v.length >= 10) || this.$t("tooltip.this-field-min-10-char"),
        (v) =>
          (v && v.length <= 250) || this.$t("tooltip.this-field-max-250-char"),
      ],
      form: {
        id: undefined,
        _method: "PUT",
        action: "save",
        is_rahasia: undefined,
        attachments: undefined,
        izin_prinsip_recipients: undefined,
        is_bom: undefined,
        direksi: undefined,
        hal: undefined,
        isi: undefined,
        reviser_nik: undefined,
        initiator_nik: undefined,
        selectedUpline: undefined,
      },
      showUplineDialog: false,
      showPeerDialog: false,
      finalData: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      izin_prinsip_recipients: { required },
      hal: { required, minLength: minLength(10), maxLength: maxLength(250) },
      isi: { required, minLength: minLength(27), maxLength: maxLength(500000) },
      direksi: {
        required: requiredIf(function () {
          return this.form.is_bom;
        }),
        dirAndRecipientMustNotSame: dirAndRecipientMustNotSame,
      },
    },
  },
  computed: {
    ...mapState("resources", ["dirSevps"]),
    ...mapState("draft", [
      "draftMode",
      "filterParams",
      "editParams",
      "singleDraft",
    ]),
    ...mapState("user", ["currentUser"]),
    ...mapState(["helpGroupRecipientDialog", "tipToHide"]),
    userIsPjp() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS"].includes(this.currentUser.level_aplikasi_elemen);
    },
    userIsKrani() {
      if (!this.currentUser) return false;
      return this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen);
    },
    mobileMenus() {
      if (this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen)) {
        return toolbarMenu.createDraftToolbarMenuMobileKrani;
      } else {
        return toolbarMenu.createDraftToolbarMenuMobile;
      }
    },
    recipientSenderSimilar() {
      if (!this.form.izin_prinsip_recipients || !this.form.direksi) return false;
      return this.similarItemExist(this.form.izin_prinsip_recipients, this.form.direksi);
    },
    editPermitMemo: {
      get() {
        return this.$store.state.draft.editPermitMemo;
      },
      set(val) {
        this.$store.commit("draft/SET_EDIT_PERMIT_MEMO", {
          show: val,
          editParams: undefined,
        });
      },
    },
    recipientList() {
      if (!this.dirSevps) return [];
      return this.dirSevps;
    },
    userHQ() {
      if (!this.currentUser) return false;
      return parseInt(this.currentUser.has_bagian) === 1;
    },
    editorDisabled() {
      if (!this.form.izin_prinsip_recipients || !this.form.hal) return true;
      if (
        this.form.izin_prinsip_recipients.length > 0 &&
        this.form.hal.length > 9
      )
        return false;
      return true;
    },
  },

  watch: {
    editPermitMemo(val) {
      //empty oldData everytime modal is showing;
      if (!val) {
        return createHelper.hideHints();
      }
      this.formReset();
      // this.$v.$reset();
      //repopulate all backend data
      this.resetRecipientPeerUpline();

      this.getDataResource({
        source: "memo-recipients",
        tipe: 'izin_prinsip',
        bgProcess: true,
      });
      this.getDataResource({
        source: "peer-revisers",
        bgProcess: true,
      });
      this.getDataResource({
        source: "atasan",
        bgProcess: true,
      });
      this.loadDraftDetail(this.editParams);
    },
    singleDraft(val) {
      if (!this.editPermitMemo) return;
      if (!val) return;
      this.form = {
        id: val.memo.id_hash,
        _method: "PUT",
        action: "save",
        is_rahasia: this.numToBool(val.memo.is_rahasia),
        attachments: [],
        izin_prinsip_recipients: this.mapId(val.penerima),
        is_bom: val.memo.approver_level === '5',
        direksi: val.memo.direksi,
        hal: val.memo.hal,
        isi: val.memo.isi,
        reviser_nik: undefined,
        initiator_nik: val.memo.initiator_nik,
        selectedUpline: undefined,
      };
      this.currPAttachments = val.memo_attachments;
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => {
        this[l] = false;
        this.loader = null;
        this.editPermitMemo = false;
      }, 3000);
    },
    tipToHide(val) {
      if (!val) return;
      createHelper.hideHint(val);
    },
  },
  methods: {
    ...mapActions("resources", ["resetRecipientPeerUpline", "getDataResource"]),
    ...mapActions(["setMessageBox"]),
    ...mapActions("draft", [
      "loadDraftDetail",
      "updateMyPermitDraft",
      "loadDraft",
      "deleteServerAttachment",
    ]),
    changeEditorData(e) {
      this.form.isi = e;
    },
    showHideUplineDialog(val) {
      this.showUplineDialog = val;
    },
    showHidePeerDialog(val) {
      this.showPeerDialog = val;
    },
    removePenerima(item) {
      const index = this.form.izin_prinsip_recipients.indexOf(item.id);
      if (index >= 0) this.form.izin_prinsip_recipients.splice(index, 1);
    },
    formReset() {
      this.$v.$reset();
      this.axiosProcessing = false;
      this.form = {
        id: undefined,
        _method: "PUT",
        action: "save",
        is_rahasia: undefined,
        attachments: undefined,
        izin_prinsip_recipients: undefined,
        is_bom: undefined,
        direksi: undefined,
        hal: undefined,
        isi: undefined,
        reviser_nik: undefined,
        initiator_nik: undefined,
        selectedUpline: undefined,
      };
      if (this.form.attachments && this.form.attachments.length > 0) {
        this.$refs.permitUpload.clear();
      }
    },
    dirSevpsFilter(item, queryText) {
      const textOne = item.nama.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1
      );
    },
    menuAction(command) {
      if (!command) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.form.action = command;

      switch (command) {
        case "save":
          this.saveThis();
          break;
        case "submit":
          this.submitThis();
          break;
        case "share_to_revision":
          this.shareThis();
          break;
        case "help":
          this.showHelper();
          break;
        default:
          break;
      }
    },
    saveThis(autoSave) {
      this.selectedUpline = null;
      this.finalData = this.clearFormData(this.form);
      this.axiosProcessing = true;
      this.updateMyPermitDraft({
        memoId: this.form.id,
        action: "save",
        formData: this.finalData,
      });
      this.$nextTick(() => {
        this.axiosProcessing = false;

        if (!autoSave) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.$store.commit("SET_CREATE_PERMIT_MEMO", false);
          }, 500);
        } else {
          this.$dialog.notify.info(
            this.$t("msgbox.error-500-please-contact-administrator"),
            {
              position: "top-left",
              timeout: 5000,
            }
          );
        }
      });
    },
    submitThis() {
      if (
        this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen) &&
        !this.form.selectedUpline
      ) {
        return (this.showUplineDialog = true);
        //open upline selector if krani
      }

      this.finalData = this.clearFormData(this.form);
      if (this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen) && this.form.selectedUpline) {
        this.finalData.append("initiator_nik", this.form.selectedUpline);
        this.finalData.delete("selectedUpline");
      }

      this.axiosProcessing = true;

      this.updateMyPermitDraft({
        memoId: this.form.id,
        action: this.form.action,
        formData: this.finalData,
      });
      this.$nextTick(() => {
        this.axiosProcessing = false;
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$store.commit("SET_CREATE_PERMIT_MEMO", false);
        }, 500);
      });
    },
    shareThis() {
      if (
        ["KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS"].includes(
          this.currentUser.level_aplikasi_elemen
        ) &&
        !this.form.reviser_nik
      ) {
        return (this.showPeerDialog = true);
        //open upline selector if krani
      }
      this.finalData = this.clearFormData(this.form);

      this.axiosProcessing = true;

      this.updateMyPermitDraft({
        memoId: this.form.id,
        action: "share_to_revision",
        formData: this.finalData,
      });
      this.$nextTick(() => {
        this.axiosProcessing = false;
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$store.commit("SET_CREATE_PERMIT_MEMO", false);
        }, 500);
      });
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (newFile.file.size > 10000000) {
          this.setMessageBox({
            snackbar: false,
            show: true,
            type: "error",
            title: "error.error-title",
            message: "error.attachment-size-is-too-big",
          });

          return prevent();
        }
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        if (!this.form.attachments) return;
        if (this.form.attachments.length === 1) {
          return this.$dialog.notify.success(
            this.$t("msgbox.attachment-will-upload-on-submit-or-save"),
            {
              position: "top-left",
              timeout: 3000,
            }
          );
        }
      }
      if (newFile && oldFile) {
        // update
        console.log("update", newFile);
      }
      if (!newFile && oldFile) {
        // remove
        //send to backend to remove by file_id
        this.deleteAttachment(oldFile);
      }
    },

    deleteAttachment(file) {
      if (!file) return;
      this.$refs.permitUpload.remove(file);
    },
    showHelper() {
      setTimeout(() => {
        createHelper.setOptions({
          hintButtonLabel: this.$t("button.i-understand"),
        });
        createHelper.addHints();
        // createHelper.refresh();
        createHelper.showHints();
      }, 500);
    },
    uplineSelected(val) {
      if (!val) return;
      this.form.selectedUpline = val.nik;
      this.menuAction("submit");
    },
    peerSelected(val) {
      if (!val) return;
      this.form.reviser_nik = val.map(({ nik }) => nik).join();
      this.menuAction("share_to_revision");
    },
    froalaIsWorking(val) {
      this.editorIsWorking = val;
    },
    mapId(obj) {
      if (!obj || obj.length === 0) return [];
      return obj.map(({ id }) => id);
    },
    confirmDeleteAttachment(file, index) {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-delete-this-attachment"),
          title: this.$t("msgbox.delete-attachment"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-delete"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = {
            memoId: this.form.id,
            id_hash: file.id_hash,
            _method: "DELETE",
          };
          this.deleteServerAttachment(formData).then((result) => {
            if (result.data == true) {
              this.currPAttachments.splice(index, 1);
            }
          });
        });
    },
  },
  filters: {
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
};
</script>
<style lang="scss">
.v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
  height: 98vh !important;
  max-height: 100vh !important;

  .fr-element.fr-view {
    height: calc(100vh - 340px) !important;
    max-height: 100vh !important;
  }
}

@media only screen and (max-width: 767px) {
  .v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
    margin: 10px !important;

    .fr-element.fr-view {
      height: calc(100vh - 440px) !important;
      max-height: 100vh !important;
    }
  }
}

.width-max {
  margin: 10px !important;
}

.height-95vh {
  min-height: 98vh;
}
</style>
